import { useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ContentLayout, PageLayout } from '../../layout'
import { UserContext } from '../../contexts'
import { MenuTitles } from '../../constants/menu'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AgxToastState } from '../../types/commonTypes'
import {
  AgxColumn,
  AgxDivider,
  AgxToast,
  AgxRow,
  AgxLabel,
  AgxButton,
  AgxDatePicker,
  AgxSelect,
  AgxCurrency,
  Option,
  AgxPartnershipLevel,
  PartnershipInfo,
} from '@urbanx/agx-ui-components'
import './partnership.scss'
import {
  GetAllAgencies,
  StaffGetAgencyPartnershipDetails,
  StaffGetPartnershipDetailsForAgency,
  StaffSetPartnershipDetailsForAgency,
} from 'services'
import { Agency } from 'types/agency'
import { useAzureAuth } from 'hooks/useAzureAuth'
import { FormPrompt } from 'components/FormPrompt'
import moment from 'moment'
import Panel from 'components/panel/Panel'
import { isEqual } from 'lodash'
import ContentLoader from 'react-content-loader'
import {
  PartnershipDetails,
  PartnershipStatistics,
} from '@urbanx/agx-ui-components/dist/cjs/types/components/portal/Reporting/types'

const PartnershipLevelOptions: Option[] = [
  {
    value: AgxPartnershipLevel.Bronze.toString(),
    label: 'Bronze',
  },
  {
    value: AgxPartnershipLevel.Silver.toString(),
    label: 'Silver',
  },
  {
    value: AgxPartnershipLevel.Gold.toString(),
    label: 'Gold',
  },
  {
    value: AgxPartnershipLevel.Platinum.toString(),
    label: 'Platinum',
  },
  {
    value: AgxPartnershipLevel.XPartner.toString(),
    label: 'X Partner',
  },
]

const PartnershipPage = () => {
  const { agencyId } = useParams()
  const queryClient = useQueryClient()
  const user = useContext(UserContext)
  const [isFormDirty, setIsFormDirty] = useState(false)

  const [, getAuthToken] = useAzureAuth()

  const { data: agencies } = useQuery<Agency[] | undefined>({
    queryKey: ['all-agencies'],
    queryFn: () => GetAllAgencies(getAuthToken),
  })

  const agency = useMemo(
    () => agencies?.find((agency) => agency.id === agencyId),
    [agencies, agencyId]
  )
  const [toastState, updateToastState] = useState<AgxToastState>({
    color: 'success',
    message: '',
    open: false,
  })

  const partnershipDetailsKey = [`partnershipDetails-${agencyId}`, agencyId]

  const {
    data: partnershipDetails,
    isLoading: partnershipDetailsLoading,
    isFetched: partnershipDetailsLoaded,
  } = useQuery<PartnershipDetails>({
    queryKey: partnershipDetailsKey,
    queryFn: (queryInfo) =>
      StaffGetPartnershipDetailsForAgency(queryInfo, getAuthToken),
    onError: (error) => {
      //eslint-disable-next-line no-console
      console.error(error)
    },
  })

  const [partnershipDetailsState, setPartnershipDetailsState] =
    useState<PartnershipDetails>(
      partnershipDetails ||
        ({
          anniversaryDate: moment().toDate(),
          partnershipLevel: AgxPartnershipLevel.Bronze,
          targetLevelAdjustment: undefined,
        } as PartnershipDetails)
    )

  const { anniversaryDate, partnershipLevel, targetLevelAdjustment } =
    partnershipDetailsState

  useEffect(() => {
    if (!partnershipDetails || partnershipDetailsLoading) return

    setPartnershipDetailsState(partnershipDetails)
  }, [partnershipDetails, partnershipDetailsLoading])

  const partnershipDetailsNotYetReady =
    partnershipDetailsLoading || !partnershipDetailsLoaded

  const {
    data: agencyPartnershipStatistics,
    isLoading: agencyAnniversaryStatisticsLoading,
    isFetched: agencyAnniversaryStatisticsFetched,
  } = useQuery<PartnershipStatistics | undefined>({
    queryKey: [
      `agencyPartnershipStatistics-${agencyId}`,
      agencyId,
      anniversaryDate,
    ],
    queryFn: (queryInfo) =>
      StaffGetAgencyPartnershipDetails(queryInfo, getAuthToken),
    onError: (error) => {
      console.error(error)
    },
    enabled: !!agencyId,
  })

  const agencyAnniversaryStatsNotYetReady =
    agencyAnniversaryStatisticsLoading || !agencyAnniversaryStatisticsFetched

  // Mutations Section
  const { mutate: updatePartnershipDetails } = useMutation(
    StaffSetPartnershipDetailsForAgency,
    {
      onSuccess: () => {
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: partnershipDetailsKey,
          }),
        ])
        updateToastState({
          color: 'success',
          message: 'Partnership details updated successfully',
          open: true,
        })
      },
      onError: () => {
        updateToastState({
          color: 'error',
          message: 'Error updating partnership details',
          open: true,
        })
      },
    }
  )

  useEffect(() => {
    setIsFormDirty(!isEqual(partnershipDetailsState, partnershipDetails))
  }, [partnershipDetailsState])

  const inputLoader = (
    <ContentLoader viewBox="0 0 100 71" height="71px" width="30%">
      <rect x="0" y="0" rx="4" ry="4" width="50%" height="21px" />
      <rect x="0" y="26" rx="4" ry="4" width="100%" height="41px" />
    </ContentLoader>
  )

  const partnershipDefaultvalue = useMemo(() => {
    return (
      PartnershipLevelOptions.find(
        (plo) =>
          plo.value ===
          (partnershipDetails?.partnershipLevel?.toString() ??
            partnershipLevel.toString())
      ) ?? PartnershipLevelOptions[0]
    )
  }, [partnershipLevel, partnershipDetails?.partnershipLevel])

  const updatedAgencyPartnershipStatistics: PartnershipStatistics | undefined =
    useMemo(() => {
      if (agencyPartnershipStatistics == undefined) return undefined

      return {
        ...agencyPartnershipStatistics,
        partnershipDetails: partnershipDetailsState,
      }
    }, [agencyPartnershipStatistics, partnershipDetailsState])

  return (
    <PageLayout
      agentName={user?.firstName || ''}
      agencyName={agency ? agency.name : ''}
      currentPageTitle={MenuTitles.PARTNERSHIP}
    >
      <ContentLayout hasSideMenu={true} activeMenu={MenuTitles.PARTNERSHIP}>
        <FormPrompt hasUnsavedChanges={isFormDirty} />
        <AgxToast selector="#agxToast" toastState={toastState} />
        <AgxColumn veryLargeGap fill>
          <AgxRow spaceBetween fill>
            <AgxLabel large>Partnership</AgxLabel>
            <AgxButton
              text="Save Changes"
              medium
              primary
              onClick={() =>
                updatePartnershipDetails({
                  agencyId: agencyId!,
                  partnershipDetails: partnershipDetailsState,
                  getAuthToken: getAuthToken,
                })
              }
            />
          </AgxRow>
          <AgxDivider />
          <Panel>
            <AgxColumn extraLargeGap fill>
              {agencyAnniversaryStatsNotYetReady ? (
                <ContentLoader viewBox="0 0 212 56" width="212px" height="56px">
                  <rect x="0" y="0" width="150" height="20"></rect>
                  <rect x="0" y="25" width="212" height="31"></rect>
                </ContentLoader>
              ) : (
                <PartnershipInfo
                  partnershipStats={updatedAgencyPartnershipStatistics}
                  isCurrentYear
                  forceDarkerColours
                  slim
                />
              )}

              {partnershipDetailsNotYetReady ? (
                <AgxRow veryLargeGap spaceBetween>
                  {inputLoader}
                  {inputLoader}
                  {inputLoader}
                </AgxRow>
              ) : (
                <AgxRow veryLargeGap>
                  <AgxDatePicker
                    id="partnershipAnniversaryDate"
                    date
                    defaultValue={
                      partnershipDetails?.anniversaryDate !== undefined
                        ? moment(partnershipDetails?.anniversaryDate).format(
                            'YYYY-MM-DD'
                          )
                        : anniversaryDate?.toString() ?? ''
                    }
                    stretch
                    label="Anniversary Date"
                    required
                    onValueChanged={({ value }) => {
                      setPartnershipDetailsState((prev: PartnershipDetails) => {
                        return {
                          ...prev,
                          anniversaryDate: moment(value).toDate(),
                        }
                      })
                    }}
                  />
                  <AgxSelect
                    id="partnershipLevel"
                    options={PartnershipLevelOptions}
                    onValueChanged={({ value }) => {
                      const newValue = Number(value) as AgxPartnershipLevel
                      if (newValue === partnershipLevel) return
                      setPartnershipDetailsState({
                        ...partnershipDetailsState,
                        partnershipLevel: Number(value) as AgxPartnershipLevel,
                      })
                    }}
                    defaultValue={partnershipDefaultvalue}
                    label="Partnership Level"
                    required
                  />
                  <AgxCurrency
                    id="partnershipTargetAdjustment"
                    defaultValue={
                      partnershipDetails?.targetLevelAdjustment?.toString() ??
                      targetLevelAdjustment?.toString() ??
                      ''
                    }
                    label="Target adjustment"
                    onInputValueChange={({ value }) => {
                      setPartnershipDetailsState((prev: PartnershipDetails) => {
                        return {
                          ...prev,
                          targetLevelAdjustment: !value
                            ? undefined
                            : Number(value),
                        }
                      })
                    }}
                    stretch
                  />
                </AgxRow>
              )}
            </AgxColumn>
          </Panel>
        </AgxColumn>
      </ContentLayout>
    </PageLayout>
  )
}

export default PartnershipPage
